import React from 'react';

import Container from 'components/layout/container';
import Layout from 'components/layout';
import PageTitle from 'components/ui/page_title';
import SEO from 'components/seo';
import SpeechBubble from 'components/ui/speech_bubble';

import data from 'data/reviews.yaml';

const Reviews = () => (
  <Layout pageType="reviews">
    <SEO title="Отзывы клиентов" />

    <Container>
      <PageTitle title="Отзывы клиентов" />
    </Container>

    <Container>
      <ul className="reviews">
        {
          data.map(({ message, name, position }) => (
            <li className="reviews__item" key={name}>
              <SpeechBubble>{message}</SpeechBubble>

              <strong className="reviews__name">{name}</strong>
              <br />
              <span className="reviews__position">{position}</span>
            </li>
          ))
        }
      </ul>
    </Container>

  </Layout>
);

export default Reviews;

import raw from 'utils/raw';
import React from 'react';
import typograf from 'utils/typograf';
import { string } from 'prop-types';

const SpeechBubble = ({ children }) => (
  <div className="speech_bubble">
    <div className="speech_bubble__body">
      {raw(typograf(children))}
    </div>
  </div>
);

SpeechBubble.propTypes = {
  children: string,
};

SpeechBubble.defaultProps = {
  children: 'Нужен текст',
};

export default SpeechBubble;
